<script>

import {mapGetters} from "vuex";
import AdvancedTable from "../../../components/elements/advanced-table.vue";

export default {
    components: {
        AdvancedTable
    },

    computed: {
        ...mapGetters({
            owners: 'field/owners',
            types: 'fieldType/all',
            fields: 'field/list'
        }),

        fields: function () {
            const fields = [
                {
                    key: "name",
                    label: this.$t('dynamic_fields::fields.columns.name').ucFirst(),
                    formatter: (value, index, item) => item && item.translation_key ? this.$t(item.translation_key).ucFirst() : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "field_type_id",
                    label: this.$t('dynamic_fields::fields.columns.field_type_id').ucFirst(),
                    formatter: (value) => value ? this.getFieldType(value) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "owner_id",
                    label: this.$t('dynamic_fields::fields.columns.owner_id').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "owner_type",
                    label: this.$t('dynamic_fields::fields.columns.owner_type').ucFirst(),
                    formatter: (value) => value ? this.getOwnerTranslation(value) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "versionable",
                    label: this.$t('dynamic_fields::fields.columns.versionable').ucFirst(),
                    formatter: (value) => value === true,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "options",
                    label: this.$t('dynamic_fields::fields.columns.options').ucFirst(),
                    formatter: (value) => value && value.length ? value : null,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];

            if (!this.fields || !this.fields.length) {
                return fields
            }

            const dynamicFields = this.fields.filter(field => field && field.options && field.options.display_as_table_column);

            dynamicFields.forEach((field) => {
                fields.push(
                    {
                        key: field.name,
                        label: field.translation_key ? this.$t(field.translation_key).ucFirst() : field.name,
                        thClass: '',
                        sortable: false
                    },
                )
            })

            return fields
        },

        queries: function () {
            if (!this.owners || !Object.keys(this.owners).length || this.index === -1) {
                return {sorting: { column: 'sort', desc: false}};
            }
            return {owner_type: this.owners[this.index].model_name, sorting: { column: 'sort', desc: false}};
        },

        item: function () {
            if (!this.owners || !Object.keys(this.owners).length || this.index === -1) {
                return {};
            }
            return this.owners[this.index];
        },
    },

    data() {
        return {
            index: 0
        };
    },

    methods: {
        setIndex: function (index) {
            this.index = index === -1 ? 0 : index;
        },

        getOwnerTranslation: function (owner) {
            const index = Object.keys(this.owners).find(key => this.owners[key].model_name === owner)

            if (index >= 0) {
                return this.$t(this.owners[index].translation_key).ucFirst()
            }

            return owner
        },

        getFieldType: function (id) {
            const type = this.types.find(type => type.id === id)

            if (type) {
                return type.translation_key ? this.$t(type.translation_key).ucFirst() : type.name
            }

            return id
        },
    },

    watch: {
        '$route.params.type': {
            deep: true,
            handler: function (value) {
                if (this.owners && this.owners.length) {
                    this.setIndex(this.owners.findIndex(owner => owner.type === value));
                }
            },
        },

        index: function (index) {
            if (this.owners && this.owners.length && index !== -1) {
                const model = this.owners[index]
                if (model) {
                    this.$store.dispatch('field/list', {owner_type: model.model_name, with: 'fieldType', sorting: { column: 'sort', desc: false}});
                } else {
                    this.$store.dispatch('field/clearList');
                }
            } else {
                this.$store.dispatch('field/clearList');
            }
        },
    },

    created() {
        // this.$store.dispatch('field/list', {owner_type: 'Appon\\Partners\\Models\\Partner', with: 'fieldType'})
    },

    mounted() {
        if (!this.owners || !this.owners.length) {
            this.$store.dispatch('field/owners');
        }
        if (!this.types || !this.types.length) {
            this.$store.dispatch('fieldType/all');
        }
        this.$store.dispatch('app/title', {key: 'dynamic_fields::fields.field', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <ul v-if="owners"
        class="nav nav-tabs nav-tabs-custom nav-justified mb-4"
        role="tablist"
    >
        <li v-for="(item, key) in owners" :key="key" class="nav-item">
            <router-link
                :class="{active: item.type === $route.params.type || (!$route.params.type && Number(key) === 0)}"
                :to="{path: $t('routes./master-data/additional-data/:type?', {type: item.type}).rmValidation() }"
                class="nav-link"
            >
                <span class="d-block">{{ $tc(item.translation_key, 2).ucFirst() }}</span>
            </router-link>
        </li>
    </ul>
    <div class="tab-content p-3 text-muted">
        <div class="tab-pane active" role="tabpanel">
            <advanced-table
                :caption="$tc('dynamic_fields::fields.field', 2).ucFirst()"
                :create-link="{ name: 'additional-data-create', params: { ownerType: item.model_name, type: item.type} }"
                :current-page="1"
                :edit-link="{ name: 'additional-data-edit' }"
                :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
                :empty-text="$t('base.empty_table').ucFirst()"
                :fields="fields"
                :per-page="10"
                :queries="queries"
                :to-be-forwarded="{type: item.type}"
                borderless
                deletable
                editable
                hover
                moduleNamespace="field"
                outlined
                permission="fields"
                primary-key="id"
                responsive="sm"
                searchable
                show-empty
                sort-by="sort"
                sort-direction="asc"
                sort-icon-left
                sortable
            >
            </advanced-table>
        </div>
    </div>
</template>
